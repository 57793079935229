/*

@media (max-width: $mdHd) {
}
@media (max-width: $mdLaptop) {
}
@media (max-width: $mdTablet) {
}
@media (max-width: $mdMobile) {
}

*/

.checkout__block {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 10px;
}

.checkout__block.confirm {
  display: none;
}

.checkout__block + .checkout__block {
  margin-top: 10px;
}

.checkout__header {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}

.checkout__body .radio-group {
  margin-top: 10px;
}

.checkout__body .radio-group .radio-group__form {
  display: none;
}

.checkout__body .radio-group.active .radio-group__form {
  display: block;
}

.checkout__body input[type=radio] {
  display: inline-block;
  width: auto;
}

.checkout__body input[type=radio] + label {
  font-weight: 600;
}

.checkout__body input[type=radio] + label:before {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #FF0000;
}

.checkout-cart {
  top: 75px;
  position: sticky;
  /*.cartnote {
    border: 1px solid #f6bc01;
    padding: 5px;
    font-weight: bold;
    color: #000;
    background-color: rgba(246, 188, 1, 0.25);
    margin: 15px 0;
    text-align: center;
  }*/
}

.checkout-cart__product {
  display: flex;
}

.checkout-cart__product .image {
  flex: 0 0 152px;
}

.checkout-cart__product .not-image {
  padding: 15px;
  align-items: center;
  flex: 1 1 50%;
  display: flex;
}

.checkout-cart__product .info {
  padding: 15px;
  flex: 1 1 50%;
}

.checkout-cart__product .quantity {
  flex: 0 0 130px;
  width: 130px;
}

.checkout-cart__product .remove {
  padding-left: 35px;
}

.checkout-cart .quantity {
  display: flex;
  align-items: center;
}

.checkout-cart .quantity .display {
  flex: 1 1 auto;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  width: 20px;
}

.checkout-cart .to-pay {
  font-size: 26px;
  width: 100%;
  text-align: right;
}

.checkout-cart .modal_checkout_order {
  display: none;
}

.popup_cart .checkout__block {
  border: none;
  position: static;
  padding: 0;
}

.popup_cart .confirm_order {
  display: none;
}

.popup_cart .modal_checkout_order {
  display: block;
}

@media (min-width: 992px) {
  .popup_cart .modal_checkout_order {
    text-align: right;
  }

  .popup_cart .modal_checkout_order a {
    margin-left: 15px;
  }
}

@media (max-width: 992px) {
  .sticky-shuttle__confirm {
    display: none;
  }

  .checkout__block.confirm {
    display: block;
  }
}

@media (max-width: 672px) {
  .checkout-cart__product {
    padding: 15px 0;
    align-items: center;
  }

  .checkout-cart__product .image {
    flex: 0 0 25%;
  }

  .checkout-cart__product .not-image {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 0 0 15px;
  }

  .checkout-cart__product .info {
    padding: 0 0 10px 0;
    flex: 1 1 100%;
  }

  .checkout-cart__product .quantity {
    flex: 0 0 130px;
    width: 130px;
  }

  .checkout-cart__product .remove {
    padding-left: 5px;
  }
}