//<ПЕРЕМЕННЫЕ>===========================================================================================================
@import "_variables.scss";
//</ПЕРЕМЕННЫЕ>===========================================================================================================

.sticky-shuttle__confirm {
  @media (max-width: $mdTablet) {
    display: none;
  }
}

.checkout {
  &__block {
    border: 1px solid $color-border;
    border-radius: 4px;
    padding: $margin-horizontal;
    &.confirm {
      display: none;
    }
    @media (max-width: $mdTablet) {
      &.confirm {
        display: block;
      }
    }
  }
  &__block + &__block {
    margin-top: $margin-horizontal;
  }
  &__header {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
  }
  &__body {
    .radio-group {
      margin-top: $margin-horizontal;
      .radio-group__form {
        display: none;
      }
      &.active {
        .radio-group__form {
          display: block;
        }
      }
    }

    input[type=radio] {
      display: inline-block;
      width: auto;
    }

    input[type=radio] + label {
      font-weight: 600;
    }

    input[type=radio] + label:before {
      //content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      background-color: #FF0000;
    }
  }
}

.checkout-cart {
  top: 75px;
  position: sticky;
  &__product {
    display: flex;
    .image {
      flex: 0 0 152px;
    }
    .not-image {
      padding: 15px;
      align-items: center;
      flex: 1 1 50%;
      display: flex;
    }
    .info {
      padding: 15px;
      flex: 1 1 50%;
    }
    .quantity {
      flex: 0 0 130px;
      width: 130px;
    }
    .remove {
      padding-left: 35px;
    }
    @media (max-width: $mdMobile) {
      padding: 15px 0;
      align-items: center;
      .image {
        flex: 0 0 25%;
      }
      .not-image {
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 0 0 15px;
      }
      .info {
        padding: 0 0 10px 0;
        flex: 1 1 100%;
      }
      .quantity {
        flex: 0 0 130px;
        width: 130px;
      }
      .remove {
        padding-left: 5px;
      }
    }
  }
  .quantity {
    display: flex;
    align-items: center;
    //border: 1px solid $color-border;
    .display {
      flex: 1 1 auto;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      width: 20px;
    }
  }
  .to-pay {
    font-size: 26px;
    //font-weight: 600;
    width: 100%;
    text-align: right;
  }
  /*.cartnote {
    border: 1px solid #f6bc01;
    padding: 5px;
    font-weight: bold;
    color: #000;
    background-color: rgba(246, 188, 1, 0.25);
    margin: 15px 0;
    text-align: center;
  }*/

  .modal_checkout_order{
    display: none;
  }
}

.popup_cart{
  .checkout__block{
    border: none;
    position: static;
    padding: 0;
  }
  .confirm_order{
    display: none;
  }
  .modal_checkout_order{
    display: block;
    @media (min-width: $mdTablet) {
      text-align: right;
      a{
        margin-left: 15px;
      }
    }
  }

}